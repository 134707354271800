import { INotifications } from 'interfaces/notifications';
import { ADD_NOTIFICATION, NotificationActionTypes, REMOVE_NOTIFICATION, SET_ANIMATION_NOTIFICATION } from './types';

const initialState = [];

export function notificationsReducer(state: INotifications = initialState, action: NotificationActionTypes): INotifications {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return [...state, action.payload];
    case REMOVE_NOTIFICATION:
      return state.filter(item => item.id !== action.payload);
    case SET_ANIMATION_NOTIFICATION:
      return state.map(item => item.id === action.payload ? {...item, animated: false} : item)
    default:
      return state;
  }
}
