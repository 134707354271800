import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'constants/api';

const order = [
  '/Playlists/Feel-Good-Tunes',
  '/Playlists/Music-for-ads',
  '/Playlists/Music-for-video',
  '/Playlists/Minimalism-Chic',
  '/Playlists/Music-for-podcasts',
  '/Playlists/Study-music',
  '/Playlists/Emotive-Instrumentals',
  '/Playlists/Atmospheric-Beats',
  '/Playlists/Relaxing-music',
  '/Playlists/Meditation-music',
  '/Playlists/Background-Music',
  '/Playlists/Funky-Grooves'
];

export const playlistsPopularApi = createApi({
  reducerPath: 'playlistsPopular',
  tagTypes: ['PlaylistsPopular'],
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getPlaylistsPopular: builder.query<any, void>({
      query: () => ({
        url: 'PlaylistPopular'
      }),
      transformResponse: (result: any) => {
        return result?.data;
      },
      providesTags: ['PlaylistsPopular']
    })
  })
});

export const { useGetPlaylistsPopularQuery, endpoints, reducer, middleware } = playlistsPopularApi;
