let localStorageSupported = null;

export const isLocalStorageSupported = () => {
  if (localStorageSupported !== null) {
    return localStorageSupported;
  }

  try {
    localStorage.setItem('check', 'check');
    localStorage.getItem('check');
    localStorage.removeItem('check');

    localStorageSupported = true;
  } catch (error) {
    localStorageSupported = false;
    console.error('localStorage not supported');
  }

  return localStorageSupported;
};

export const storage = {
  getItem: (key: string) => {
    if (isLocalStorageSupported()) {
      let data = localStorage.getItem(key);

      if (!data) {
        return;
      }

      return data;
    }
  },
  setItem(key: string, value: any) {
    if (isLocalStorageSupported()) {
      localStorage.setItem(key, value)
    }
  },
  removeItem(key: string) {
    if (isLocalStorageSupported()) {
      localStorage.removeItem(key);
    }
  }
}
