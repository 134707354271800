import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { STATIC_API_URL } from 'constants/api';
// const REACT_APP_API_URL = 'https://static-eu.gcp.mubert.com/backend_content/render/test/other/faq.json'
// const BACKEND_API_URL = 'https://static-eu.gcp.mubert.com/backend_content/render/prod/other/faq.json';

export const faqApi = createApi({
  reducerPath: 'faq',
  tagTypes: ['faq'],
  baseQuery: fetchBaseQuery({
    baseUrl: STATIC_API_URL
  }),
  endpoints: (builder) => ({
    getFaq: builder.query<any, void>({
      query: () => ({
        url: `/other/faq.json?${+new Date()}`
      }),
      transformResponse: (result: any) => result,
      providesTags: ['faq']
    })
  })
});

export const { useGetFaqQuery, endpoints, reducer, middleware } = faqApi;
