import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ITrack } from 'interfaces';
import { API_URL } from 'constants/api';
import { addNotification  } from '../notifications/actions';

type DownloadedTracksParams = {
  page: number
};

type DownloadedTracksResponse = {
  pages_count: number;
  tracks_count: number;
  tracks: ITrack[];
};

export const downloadedTracksApi = createApi({
  reducerPath: 'downloadedTracks',
  tagTypes: ['DownloadedTracks'],
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getDownloadedTrackList: builder.query<DownloadedTracksResponse, DownloadedTracksParams>({
      query: ({page}) => ({
        url: 'TrackListDownloaded',
        method: 'POST',
        body: {
          params: {
            page
          }
        }
      }),
      transformResponse: (result: any) => result?.data,
      providesTags: ['DownloadedTracks']
    }),
    removeDownloadedTrackById: builder.mutation<void, string>({
      query: (sessionId) => ({
        url: `TrackDelete`,
        method: 'POST',
        body: {
          params: {
            session_id: sessionId
          }
        }
      }),
      invalidatesTags: ['DownloadedTracks']
    }),
    editTrackName: builder.mutation<any, {sessionId: string, name: string; page: number}>({
      query: ({sessionId, name}) => ({
        url: `TrackEdit`,
        method: 'POST',
        body: {
          method: 'TrackEdit',
          params: {
            session_id: sessionId,
            name
          }
        }
      }),
      async onQueryStarted({ sessionId, name, page }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTrack } = await queryFulfilled

          if (updatedTrack.data?.code !== 1) {
            dispatch(addNotification({
              title: 'Unknown error happened, please try again',
            }));
          } else {
            dispatch(
              downloadedTracksApi.util.updateQueryData('getDownloadedTrackList', { page }, (draft) => {
                draft.tracks = draft.tracks.map(track => {
                  if (track.session_id === sessionId) {
                    track.name = name
                  }
                  return track
                })
              })
            )
          }
        } catch (e) {
          dispatch(addNotification({
            title: 'Unknown error happened, please try again',
          }));
          console.log(e)
        }
      },
    })
  })
});

export const {
  useGetDownloadedTrackListQuery,
  useRemoveDownloadedTrackByIdMutation,
  useEditTrackNameMutation,
  endpoints,
  reducer,
  middleware
} = downloadedTracksApi;
