import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ITrack } from 'interfaces';
import { API_URL } from 'constants/api';

type StaffPicksTracksParams = {
  tags: string[],
  page: number
};

type StaffPicksTracksResponse = {
  pages_count: number;
  tracks_count: number;
  tracks: ITrack[];
};

export const staffPicksTracksApi = createApi({
  reducerPath: 'staffPicksTracks',
  tagTypes: ['StaffPicksTracks'],
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getStaffPicksTracksList: builder.query<StaffPicksTracksResponse, StaffPicksTracksParams>({
      query: ({tags, page}) => ({
        url: 'TrackListStaffPicks',
        method: 'POST',
        body: {
          params: {
            tags,
            page
          }
        }
      }),
      transformResponse: (result: any) => result?.data,
      providesTags: ['StaffPicksTracks']
    })
  })
});

export const {
  useGetStaffPicksTracksListQuery,
  endpoints,
  reducer,
  middleware
} = staffPicksTracksApi;
