import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'constants/api';
import { addMissingRoute } from '../missing-routes/actions';

export const tagsApi = createApi({
  reducerPath: 'tags',
  tagTypes: ['Tags'],
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getTagById: builder.query<any, string>({
      query: (id) => ({
        url: '',
        params: {
          method: 'PageCheck',
          routing: id
        }
      }),
      transformResponse: (result: any) => {
        if (result?.data?.code === 1) {
          return result.data.tag;
        }

        return null;
      },
      async onQueryStarted(page, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (!data) {
            dispatch(addMissingRoute(`/render/tags${page}`));
          }
        } catch (err) {}
      },
      providesTags: ['Tags']
    })
  })
});

export const { useGetTagByIdQuery, endpoints, reducer, middleware } = tagsApi;
