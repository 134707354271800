import axios from 'axios';

type TrackInfoProps = {
  id: string | number;
  urlType?: string;
};

type GenerateTrackInfoProps = {
  id: string | number;
  licenseId: string;
  generateLicense?: boolean;
}

export const trackInfo = ({ id, urlType = 'all' }: TrackInfoProps) =>
  axios.get(`/v1?method=TrackInfo&session_id=${id}&url_type=${urlType}`).then((response) => {
    return response && response.data && response.data.data && response.data.data.track;
  });

export const generateTrackInfo = ({ id, licenseId, generateLicense = false }: GenerateTrackInfoProps) =>
  axios
    .post('/v1', {
      method: 'TrackInfo',
      params: {
        session_id: id,
        stripe_price_id: licenseId,
        generate_license: generateLicense
      }
    })
    .then((response) => {
      if (response && response.data && response.data.data && response.data.data.track) {
        return response.data.data.track;
      } else if (response && response.data && response.data.error) {
        return Promise.reject(response.data.error);
      }
    });
