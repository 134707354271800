import axios from 'axios';


export const cancelSubscription = (priceId: string) =>
  axios.post('/v1/StripeSubscriptionCancel', {
    method: 'StripeSubscriptionCancel',
    params: {
      stripe_price_id: priceId
    }
  });
