import { IPricingData } from '../../interfaces/pricing-info';

export const SET_PRICING_DATA = 'SET_PRICING_DATA';
export const SET_IS_PRICING_LOADING = 'SET_IS_PRICING_LOADING';

export interface SetPricingDataAction {
  type: typeof SET_PRICING_DATA;
  payload: { pricingData: IPricingData, isLoggedIn: boolean };
}

export interface SetIsPricingLoadingAction {
  type: typeof SET_IS_PRICING_LOADING;
  payload: boolean;
}

export type PricingInfoActionTypes = SetPricingDataAction | SetIsPricingLoadingAction;
