import { IPricingInfo } from '../../interfaces/pricing-info';
import { PricingInfoActionTypes, SET_IS_PRICING_LOADING, SET_PRICING_DATA } from './types';

const initialState = {
  isLoading: false,
  isLoadedForLoggedInUser: false,
  data: {
    subscription: [],
    singlePurchase: [],
    blackFriday: []
  }
}

export const pricingInfoReducer = (state: IPricingInfo = initialState, action: PricingInfoActionTypes) => {
  switch (action.type) {
    case SET_IS_PRICING_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case SET_PRICING_DATA:
      return {
        ...state,
        data: action.payload.pricingData,
        isLoadedForLoggedInUser: action.payload.isLoggedIn
      };
    default:
      return state;
  }
}
