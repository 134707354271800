import { trackInfo, generateTrackInfo } from './track-info';
import { priceInfo } from './price-info';
import { logout } from './logout';
import { cancelSubscription } from './cancel-subscription';
import { stripeSubscribe } from './stripe-subscribe';
import { stripeOrder } from './stripe-order';
import { generateTrack } from './generate-track';
import { logError } from './log-error';
import { userProfile } from './user-profile';
import { authByEmail } from './auth-by-email';

export const api = {
  trackInfo,
  priceInfo,
  logout,
  cancelSubscription,
  stripeSubscribe,
  stripeOrder,
  generateTrack,
  logError,
  userProfile,
  authByEmail,
  generateTrackInfo
};
