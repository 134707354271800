import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { STATIC_API_URL } from 'constants/api';

type LocalizedText = {
  en: string;
  ko: string;
  es: string;
};

export type New = {
  date: number;
  title: LocalizedText;
  preview_description: LocalizedText;
  main_description: LocalizedText;
  link: string;
  images: string[];
  mobile_images: string[];
};

export const whatsNewApi = createApi({
  reducerPath: 'whatsNew',
  tagTypes: ['whatsNew'],
  baseQuery: fetchBaseQuery({
    baseUrl: STATIC_API_URL
  }),
  endpoints: (builder) => ({
    getWhatsNew: builder.query<New[], void>({
      query: () => ({
        url: `/other/whatsnew.json?t=${+new Date()}`
      }),
      transformResponse: (result: New[]) => result,
      providesTags: ['whatsNew']
    })
  })
});

export const { useGetWhatsNewQuery, endpoints, reducer, middleware } = whatsNewApi;
