import { IModals } from 'interfaces';
import { HIDE_MODAL, SHOW_MODAL, ModalActionTypes } from './types';

const initialState = {
  isVisible: false,
  name: '',
  showMask: true,
  deferredModals: []
};

export const modalsReducer = (
  state: IModals = initialState,
  action: ModalActionTypes
): IModals => {
  switch (action.type) {
    case SHOW_MODAL:
      if (state.isVisible) {
        return {
          ...state,
          deferredModals: [
            ...state.deferredModals,
            {
              name: action.payload.name,
              onClose: action.payload.onClose,
              onComplete: action.payload.onComplete,
              additionalProps: action.payload.props,
              small: action.payload.small,
              extraSmall: action.payload.extraSmall,
              width: action.payload.width,
              large: action.payload.large,
              big: action.payload.big,
              fullHeight: action.payload.fullHeight,
              showMask: action.payload.showMask === undefined
                ? true
                : action.payload.showMask,
              isVisible: true,
              closable: action.payload.closable,
              maskClosable: action.payload.maskClosable
            },
          ]
        }
      }

      return {
        ...state,
        name: action.payload.name,
        onClose: action.payload.onClose,
        onComplete: action.payload.onComplete,
        additionalProps: action.payload.props,
        small: action.payload.small,
        extraSmall: action.payload.extraSmall,
        width: action.payload.width,
        large: action.payload.large,
        big: action.payload.big,
        fullHeight: action.payload.fullHeight,
        showMask: action.payload.showMask === undefined
          ? true
          : action.payload.showMask,
        isVisible: true,
        closable: action.payload.closable,
        maskClosable: action.payload.maskClosable
      };
    case HIDE_MODAL:
      if (state.deferredModals.length > 0) {
        return {
          ...state,
          ...state.deferredModals[0],
          deferredModals: state.deferredModals.slice(1)
        }
      }

      return {
        ...state,
        closable: true,
        maskClosable: true,
        showMask: true,
        onComplete: undefined,
        isVisible: false
      };
    default:
      return state;
  }
};
