import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';
import axios from 'axios';
import 'url-search-params-polyfill';
import 'core-js/features/object/entries';
import 'smoothscroll-polyfill';

import { Provider } from 'react-redux';
import { history, rootReducer } from './redux/store';
import App from './App';
import { patchNode } from './patch-node';

import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';
import { applyMiddleware, createStore, compose } from 'redux';
import { middleware as trackListMiddleware } from './redux/api/tracks';
import { middleware as trackMusicTreeMiddleware } from './redux/api/track-music-tree';
import { middleware as sideBarStructureMiddleware } from './redux/api/side-bar-structure';
import { middleware as playlistsPopularMiddleware } from './redux/api/playlists-popular';
import { middleware as similarTracksMiddleware } from './redux/api/similar-tracks';
import { middleware as tagsMiddleware } from './redux/api/tags';
import { middleware as pageInfoMiddleware } from './redux/api/page-info';
import { middleware as staffPicksTracksMiddleware } from './redux/api/staff-picks-tracks';
import { middleware as downloadedTracksMiddleware } from './redux/api/my-downloaded-tracks';
import { middleware as similarSearchMiddleWare } from './redux/api/similar-search';
import { middleware as faqMiddleware } from './redux/api/faq';
import { middleware as whatsNewMiddleware } from './redux/api/whatsnew';
import { middleware as profilePageMiddleware } from './redux/api/profile-page';
import { middleware as socialLinksMiddleware } from './redux/api/social-links';
import { middleware as paymentsMiddleware } from './redux/api/payments';
import thunk from 'redux-thunk';
import { storage } from 'utils/storage';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from 'constants/common';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'https://stage.mubert.com';

loadableReady(() => {
  const preloadedState = window.__PRELOADED_STATE__;
  mixpanel.init(MIXPANEL_TOKEN, { track_pageview: true });

  try {
    preloadedState.generator.generatedTracks = JSON.parse(storage.getItem('generated-track-ids'));
    // preloadedState.generator.isHideByUser = storage.getItem('is-generator-hidden') === 'true';
    // preloadedState.generator.isOpened = storage.getItem('is-generator-hidden') !== 'true' && (
    //   window.location.pathname === '/render' ||
    //   window.location.pathname === '/render/'
    // );
  } catch {}

  delete window.__PRELOADED_STATE__;
  const composeEnhancers =
    typeof window === 'object' &&
    process.env.REACT_APP_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  patchNode();

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        trackListMiddleware,
        similarTracksMiddleware as any,
        playlistsPopularMiddleware as any,
        sideBarStructureMiddleware as any,
        trackMusicTreeMiddleware as any,
        tagsMiddleware as any,
        pageInfoMiddleware as any,
        staffPicksTracksMiddleware as any,
        downloadedTracksMiddleware as any,
        similarSearchMiddleWare as any,
        faqMiddleware as any,
        whatsNewMiddleware as any,
        profilePageMiddleware as any,
        socialLinksMiddleware as any,
        paymentsMiddleware as any
      )
    )
  );

  hydrateRoot(
    document.getElementById('root'),
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
