import { PlayerMetaActionTypes, SET_PLAYER_META } from './types';


const initialState: object = null;

export const playerMetaReducer = (
  state: object = initialState,
  action: PlayerMetaActionTypes
): object => {
  switch (action.type) {
    case SET_PLAYER_META:
      return action.payload;
    default:
      return state;
  }
};
