export const MAILTO_CONTACT = 'mailto:business@mubert.com';
export const FOOTER_LINKS = {
  website: {
    privacyPolicy: '/documents/mubert_website_privacy_policy.pdf',
    termsOfUse: '/documents/mubert_website_tou.pdf',
    dmcaPolicy: '/documents/mubert_website_dmca_policy.pdf'
  },
  render: {
    privacyPolicy: '/documents/mubert_render_privacy_policy.pdf',
    termsOfUse: '/documents/mubert_render_tou.pdf',
    dmcaPolicy: '/documents/mubert_render_dmca_policy.pdf',
    cookiesPolicy: '/documents/mubert_render_cookies.pdf',
    singleTrackLicense: '/documents/mubert_render_single_track_license_agreement.pdf',
    subscriptionLicense: '/documents/mubert_render_subscription_license_agreement.pdf'
  }
};
export const SUBSCRIPTION_TYPEFORM = 'https://mubertapp.typeform.com/to/nl5cyF#page=pricing_popup&utm=business_subscription';
export const SINGLE_TRACK_TYPEFORM = 'https://mubertapp.typeform.com/to/nl5cyF#page=pricing_popup&utm=reselling_license';
export const MUBERT_API_URL = 'https://pitch.com/v/mubert-api-2024-7vq5ei';

export const POLLING_TIMEOUT = 10000;
export const MIXPANEL_TOKEN = 'bb7490f7ad30d95cf6297e2a47021dd2'
export const TURNSTILE_SITE_KEY = process.env.REACT_APP_TURNSTILE_SITE_KEY || '0x4AAAAAAAN6ukwc0fNhQAiS'
export const LOCAL_TURNSTILE_TOKEN = '39q1bxK6p6ep9D8SGNDbstegPYrLvww5';
export const NO_TURNSTILE_TOKEN = 'no_turnstile_token';