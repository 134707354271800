import {
  SET_CURRENT_TRACK,
  SET_TRACK_INFO,
  SET_TIME_PLAYING,
  SET_TRACK_PLAYING,
  SetTimePlayingAction,
  SetTrackPlayingAction,
  TrackActionTypes
} from './types';


const initialState: string = '';

export const trackReducer = (
  state: string = initialState,
  action: TrackActionTypes
): string => {
  switch (action.type) {
    case SET_CURRENT_TRACK:
      return action.payload;
    default:
      return state;
  }
};

export const trackInfoReducer = (
  state: any = {},
  action: TrackActionTypes
): any => {
  switch (action.type) {
    case SET_TRACK_INFO:
      return {
        ...state,
        [action.payload.session_id]: action.payload
      };
    default:
      return state;
  }
};

export const playingReducer = (
  state: boolean = false,
  action: SetTrackPlayingAction
): boolean => {
  switch (action.type) {
    case SET_TRACK_PLAYING:
      return action.payload;
    default:
      return state;
  }
};

export const timeReducer = (
  state: number = 0,
  action: SetTimePlayingAction
): number => {
  switch (action.type) {
    case SET_TIME_PLAYING:
      return action.payload;
    default:
      return state;
  }
};

