import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';
import { ErrorBoundary } from 'layout/components/error-boundary';
import LogoSvg from './assets/img/logo-header.svg';
import { usePathPrefix } from 'hooks/useLanguage';
import './App.scss';
import { useIsRouteMissing } from './redux/missing-routes/selectors';
import { useLocation } from 'hooks/useLocation';

const MainPage = loadable(() => import('./pages/Main'));
const RenderPage = loadable(() => import('./pages/render'));
const AboutPage = loadable(() => import('./pages/about'));
const ContactPage = loadable(() => import('./pages/ContactPage'));
const NotFoundPage = loadable(() => import('./pages/sessions/404'));
const ProductsPage = loadable(() => import('./pages/products'));
// const LpPage = loadable(() => import('./pages/lp'));
const SessionsPage = loadable(() => import('./pages/sessions'));
const RenderDocumentsPage = loadable(() => import('./pages/render-documents'));
const WebsiteDocumentsPage = loadable(() => import('./pages/website-documents'));

const App = () => {
  const prefix = usePathPrefix();
  const { pathname } = useLocation()
  const isRouteMissing = useIsRouteMissing(pathname)

  if (isRouteMissing) {
    return <NotFoundPage />
  }

  return (
    <ErrorBoundary>
      <Switch>
        <Route path={`${prefix}/`} exact component={MainPage} />
        <Route path={`${prefix}/about-us`} exact component={AboutPage} />
        <Route path={`${prefix}/contact`} exact component={ContactPage} />
        <Route path={`${prefix}/render/sign-in`} component={SessionsPage} />
        <Route path={`${prefix}/render/sign-up`} component={SessionsPage} />
        <Route path={`${prefix}/render/forget`} component={SessionsPage} />
        <Route path={`${prefix}/render/confirm`} component={SessionsPage} />
        <Route path={`${prefix}/render/docs/:id?`} component={RenderDocumentsPage} />
        <Route path={`${prefix}/docs/:id?`} component={WebsiteDocumentsPage} />
        <Route path={`${prefix}/render`} component={RenderPage} />
        <Route path={`${prefix}/products`} component={ProductsPage} />
        {/*<Route path={`${prefix}/lp/:id`} component={LpPage} />*/}
        {/*<Route path={`${prefix}/page-not-found`} component={NotFoundPage} />*/}
        <Route path='*' component={NotFoundPage} />
      </Switch>
    </ErrorBoundary>
  );
};

const Maintenance = () => {
  return (
    <div style={{ textAlign: 'center', paddingTop: 100 }}>
      <Helmet>
        <title>
          Mubert - Thousands of Staff-Picked Royalty-Free Music Tracks for Streaming, Videos,
          Podcasts, Commercial Use and Online Content
        </title>
      </Helmet>
      <img src={LogoSvg} alt='logo' />
      <h3 style={{ textAlign: 'center' }}>
        Mubert is currently down for maintenance.
        <br />
        We'll be back in a few hours with some improvements ⚡
      </h3>
    </div>
  );
};

const isMaintenance =
  typeof window === 'object'
    ? window.__IS_MAINTENANCE__ === true
    : process.env.IS_MAINTENANCE === 'true';

const AppOrMaintenance = isMaintenance ? Maintenance : App;

export default AppOrMaintenance;
