import React from 'react';
import { INotification } from 'interfaces/notifications';
import { LANGUAGE_PATH_PREFIXES } from 'constants/languages';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, SET_ANIMATION_NOTIFICATION } from './types';
import { history } from '../../redux/store';

const addNotificationAction = (notification: INotification) => ({
  type: ADD_NOTIFICATION,
  payload: notification
});

type AddNotificationProps = {
  title: React.ReactNode;
  action?: string;
  actionUrl?: string;
  onActionClick?: Function;
  onClose?: Function;
  preventAutoClose?: boolean;
};

const closeNotificationAndOpenUrl = (id: string, url: string) => (dispatch, getState) => {
  const language = getState().settings.language;
  const prefix = LANGUAGE_PATH_PREFIXES[language];

  dispatch(setNoAnimationNotification(id));
  setTimeout(() => {
    dispatch(removeNotification(id));
  }, 0);
  setTimeout(() => {
    history.push(`${prefix}${url}`);
  }, 400);
}

export const addNotification =
  ({ title, action, actionUrl, onActionClick, onClose, preventAutoClose }: AddNotificationProps) =>
  (dispatch, getState) => {
    const id = Math.random().toString();
    const handleClick = () => {
      if (onActionClick) {
        onActionClick(id);
      } else if (actionUrl) {
        dispatch(closeNotificationAndOpenUrl(id, actionUrl));
      }
    }


    dispatch(addNotificationAction({
      id,
      title,
      action,
      preventAutoClose,
      onClose,
      onActionClick: handleClick
    }))
  };

export const removeNotification = (id: string) => ({
  type: REMOVE_NOTIFICATION,
  payload: id
});

export const setNoAnimationNotification = (id: string) => ({
  type: SET_ANIMATION_NOTIFICATION,
  payload: id
});
