export const TOGGLE_TOP_MENU = '[User] Toggle topMenu';
export const HIDE_TOP_MENU = '[User] Hide topMenu';
export const HIDE_REDIRECT_PREVIEW = 'HIDE_REDIRECT_PREVIEW';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const TOGGLE_THEME = 'TOGGLE_THEME';
export const SET_LIGHT_THEME = 'SET_LIGHT_THEME';
export const SET_DARK_THEME = 'SET_DARK_THEME';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const TOGGLE_FAQ_ITEM = 'TOGGLE_FAQ_ITEM';
export const SET_ACTIVE_FAQ_ITEM = 'SET_ACTIVE_FQA_ITEM';

export interface ToggleTopMenu {
  type: typeof TOGGLE_TOP_MENU;
}

export interface HideTopMenu {
  type: typeof HIDE_TOP_MENU;
}

export interface HideRedirectPreview {
  type: typeof HIDE_REDIRECT_PREVIEW;
}

export interface SetRedirectUrl {
  type: typeof SET_REDIRECT_URL;
  payload: string;
}

export interface ToggleTheme {
  type: typeof TOGGLE_THEME;
}

export interface SetLightTheme {
  type: typeof SET_LIGHT_THEME;
}

export interface SetDarkTheme {
  type: typeof SET_DARK_THEME;
}

export interface SetLanguage {
  type: typeof SET_LANGUAGE;
  payload: string;
}

export interface ToggleFaqItem {
  type: typeof TOGGLE_FAQ_ITEM;
  payload: string;
}

export interface SetActiveFaqItem {
  type: typeof SET_ACTIVE_FAQ_ITEM;
  payload: string;
}

export type SettingsActionTypes =
  | ToggleTopMenu
  | HideTopMenu
  | HideRedirectPreview
  | SetRedirectUrl
  | ToggleTheme
  | SetLightTheme
  | SetDarkTheme
  | SetLanguage
  | ToggleFaqItem
  | SetActiveFaqItem;
