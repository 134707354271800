import { ADD_MISSING_ROUTE, MissingRoutesTypes } from './types';

const initialState = {};

export function missingRoutesReducer(
  state: Record<string, boolean> = initialState,
  action: MissingRoutesTypes
): Record<string, boolean> {
  switch (action.type) {
    case ADD_MISSING_ROUTE:
      return {
        ...state,
        [action.payload]: true
      };
    default:
      return state;
  }
}
