export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const SET_ANIMATION_NOTIFICATION = 'SET_ANIMATION_NOTIFICATION';

export interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION;
  payload: any;
}

export interface RemoveNotificationActon {
  type: typeof REMOVE_NOTIFICATION;
  payload: string;
}

export interface SetAnimationNotificationActon {
  type: typeof SET_ANIMATION_NOTIFICATION;
  payload: string;
}

export type NotificationActionTypes = AddNotificationAction | RemoveNotificationActon | SetAnimationNotificationActon;
