export const SET_IS_PURCHASING = 'SET_IS_PURCHASING';
export const SET_PURCHASE = 'SET_PURCHASE';

export interface SetPurchaseAction {
  type: typeof SET_PURCHASE;
  payload: {
    price_id: string;
    session_id?: string;
  };
}

export interface SetIsPurchasingAction {
  type: typeof SET_IS_PURCHASING;
  payload: boolean;
}

export type PurchaseActionTypes =
  | SetPurchaseAction
  | SetIsPurchasingAction;
