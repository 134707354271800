import { SET_TURNSTILE_LOADED, SET_TURNSTILE_TOKEN, TurnstileTokeanActionTypes } from './types';

const initialState = {
  token: null,
  isLoaded: false
};

export const turnstileTokenReducer = (state = initialState, action: TurnstileTokeanActionTypes): {
  token: null | string;
  isLoaded: boolean;
} => {
  switch (action.type) {
    case SET_TURNSTILE_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case SET_TURNSTILE_LOADED:
      return {
        ...state,
        isLoaded: true
      };
    default:
      return state;
  }
};
