import {
  HIDE_REDIRECT_PREVIEW,
  HIDE_TOP_MENU,
  SET_REDIRECT_URL,
  SET_LIGHT_THEME,
  SET_DARK_THEME,
  TOGGLE_THEME,
  SettingsActionTypes,
  TOGGLE_TOP_MENU,
  SET_LANGUAGE,
  TOGGLE_FAQ_ITEM,
  SET_ACTIVE_FAQ_ITEM
} from './types';

import { IAppSettings } from '../../interfaces/settings';
import { DEFAULT_SETTINGS } from './settings';

const initialState: IAppSettings = DEFAULT_SETTINGS;

export const settingsReducer = (
  state: IAppSettings = initialState,
  action: SettingsActionTypes
): IAppSettings => {
  switch (action.type) {
    case TOGGLE_TOP_MENU:
      return { ...state, topMenuOpened: !state.topMenuOpened };
    case HIDE_TOP_MENU:
      return { ...state, topMenuOpened: false };
    case HIDE_REDIRECT_PREVIEW:
      return { ...state, isRedirectPreviewVisible: false };
    case SET_REDIRECT_URL:
      return { ...state, redirectUrl: action.payload };
    case TOGGLE_THEME:
      return { ...state, isDarkTheme: !state.isDarkTheme };
    case SET_LIGHT_THEME:
      return { ...state, isDarkTheme: false };
    case SET_DARK_THEME:
      return { ...state, isDarkTheme: true };
    case SET_LANGUAGE:
      return { ...state, language: action.payload };
    case TOGGLE_FAQ_ITEM:
      return {
        ...state,
        faqItems: {
          ...state.faqItems,
          opened: {
            ...state.faqItems.opened,
            [action.payload]: !state.faqItems.opened[action.payload]
          }
        }
      };
    case SET_ACTIVE_FAQ_ITEM:
      return {
        ...state,
        faqItems: {
          ...state.faqItems,
          active: action.payload
        }
      };
    default:
      return { ...state };
  }
};
