import axios from 'axios';

type GenerateTrackParams = {
  message: string;
  error: string;
  stack?: string;
  componentsStack?: string;
  errorCode: string;
  parsedStack?: string;
  loggedInfo: object;
  userAgent: string;
  url: string;
};

export const logError = ({ message, error, stack, componentsStack, errorCode, parsedStack, loggedInfo, userAgent, url }: GenerateTrackParams) =>
  axios.post('/api/log-error', {
    message,
    error,
    stack,
    componentsStack,
    errorCode,
    parsedStack,
    loggedInfo,
    userAgent,
    url
  });
