import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'constants/api';

export const sideBarStructureApi = createApi({
  reducerPath: 'sideBarStructure',
  tagTypes: ['SideBarStructure'],
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getSideBarStructure: builder.query<any, string>({
      query: (lang) => ({
        url: 'SideBarStructure',
        params: { lang }
      }),
      transformResponse: (result: any) => result?.data,
      providesTags: ['SideBarStructure']
    })
  })
});

export const { useGetSideBarStructureQuery, endpoints, reducer, middleware } = sideBarStructureApi;
