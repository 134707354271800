export const DEFAULT_SETTINGS = {
  topMenuOpened: false,
  isMobile: false,
  isRedirectPreviewVisible: true,
  isDarkTheme: false,
  language: 'en',
  faqItems: {
    active: null,
    opened: {}
  },
};
