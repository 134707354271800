import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'constants/api';
import { getTurnstileToken } from 'utils/turnstile';
import { delay } from 'utils/delay';

type SearchTrackParams = {
  url: string;
  token: string;
};

const withDelay =
  (baseQuery, delayTimeout = 2000) =>
  async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (args.url === 'TrackSimSearch') {
      await delay(delayTimeout);
    }
    return result;
  };

export const similarSearchApi = createApi({
  reducerPath: 'similarSearch',
  tagTypes: ['SimilarSearch'],
  baseQuery: withDelay(fetchBaseQuery({ baseUrl: API_URL })),
  endpoints: (builder) => ({
    getSimilarSearch: builder.query<any, void>({
      query: () => ({
        url: 'TrackSimSearchGetResult'
      }),
      transformResponse: (result: any) => result?.data,
      providesTags: ['SimilarSearch']
    }),
    searchTrackByUrl: builder.mutation<any, SearchTrackParams>({
      query: ({ url, token }) => ({
        url: `TrackSimSearch`,
        method: 'POST',
        body: {
          token: getTurnstileToken(token),
          params: {
            url
          }
        }
      }),
      invalidatesTags: ['SimilarSearch']
    }),
  })
});

export const {
  useGetSimilarSearchQuery,
  useSearchTrackByUrlMutation,
  endpoints,
  reducer,
  middleware
} = similarSearchApi;
