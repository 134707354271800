export const TOGGLE_GENERATOR = 'TOGGLE_GENERATOR';
export const TOGGLE_GENERATOR_BY_CLICK = 'TOGGLE_GENERATOR_BY_CLICK';
export const RESET_IS_HIDE_BY_USER = 'RESET_IS_HIDE_BY_USER';
export const ADD_GENERATED_TRACK = 'ADD_GENERATED_TRACK';
export const REMOVE_GENERATED_TRACK = 'REMOVE_GENERATED_TRACK';
export const HIDE_BY_USER = 'HIDE_BY_USER';

export interface ToggleGeneratorAction {
  type: typeof TOGGLE_GENERATOR;
  payload?: boolean;
}

export interface ToggleGeneratorByClickAction {
  type: typeof TOGGLE_GENERATOR_BY_CLICK;
  payload?: boolean;
}

export interface ResetIsHideByUserAction {
  type: typeof RESET_IS_HIDE_BY_USER;
}

export interface AddGeneratedTrackAction {
  type: typeof ADD_GENERATED_TRACK;
  payload: string;
}

export interface RemoveGeneratedTrackAction {
  type: typeof REMOVE_GENERATED_TRACK;
  payload: string;
}

export interface HideByUserAction {
  type: typeof HIDE_BY_USER;
}


export type GeneratorActionTypes =
  | ToggleGeneratorAction
  | ToggleGeneratorByClickAction
  | ResetIsHideByUserAction
  | AddGeneratedTrackAction
  | RemoveGeneratedTrackAction
  | HideByUserAction;
