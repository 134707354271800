import { LoginActionTypes, SET_LOGGED_IN } from './types';


const initialState: object = null;

export const loginReducer = (
  state: object = initialState,
  action: LoginActionTypes
): object => {
  switch (action.type) {
    case SET_LOGGED_IN:
      return action.payload;
    default:
      return state;
  }
};
