import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'constants/api';
import { addNotification } from '../notifications/actions';

interface AddLinkParams {
  type: SocialLinkType;
  link: string;
}

export enum SocialLinkType {
  YouTube = 'youtube',
  Facebook = 'facebook',
  Instagram = 'instagram',
  TikTok = 'tiktok',
  Twitch = 'twitch',
  Twitter = 'twitter',
  Vimeo = 'vimeo',
  Other = 'other'
}

export interface SocialLinkItem {
  id: string;
  link: string;
  type: SocialLinkType;
}

export const socialLinksApi = createApi({
  reducerPath: 'socialLinks',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  tagTypes: ['SocialLinkList'],
  endpoints: (builder) => ({
    userSocialLinkList: builder.query<SocialLinkItem[], void>({
      query: () => ({
        url: 'UserSocialLinkList'
      }),
      transformResponse: (result: { data: SocialLinkItem[] }) => result?.data,
      providesTags: ['SocialLinkList']
    }),
    userSocialLinkAdd: builder.mutation<void, AddLinkParams>({
      query: ({ type, link }) => ({
        url: 'UserSocialLinkAdd',
        method: 'POST',
        body: {
          type,
          link
        }
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const UNKNOWN_ERROR_MESSAGE = 'Unknown error during adding the URL';
        try {
          const { data } = await queryFulfilled;
          const error = (data as any)?.error;

          if ((data as any).data as SocialLinkItem) {
            dispatch(
              socialLinksApi.util.updateQueryData(
                'userSocialLinkList',
                undefined,
                (currentList) => {
                  currentList.push((data as any).data as SocialLinkItem);
                }
              )
            );

            dispatch(
              addNotification({
                title: 'Channel added successfully'
              })
            );
          } else if (error) {
            throw new Error(error?.text || UNKNOWN_ERROR_MESSAGE);
          } else {
            throw new Error(UNKNOWN_ERROR_MESSAGE);
          }
        } catch (e: any) {
          dispatch(
            addNotification({
              title: e.message || UNKNOWN_ERROR_MESSAGE
            })
          );
          // throw new Error(UNKNOWN_ERROR_MESSAGE);
        }
      }
    }),
    userSocialLinkDelete: builder.mutation<void, string>({
      query: (link_id) => ({
        url: 'UserSocialLinkDelete',
        method: 'POST',
        body: {
          link_id
        }
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        console.log(`id = ${id}`);
        try {
          const { data } = await queryFulfilled;
          const error = (data as any)?.error;

          if (error) {
            dispatch(
              addNotification({
                title: error?.text || 'Unknown error during channel removal'
              })
            );
          } else {
            dispatch(
              socialLinksApi.util.updateQueryData(
                'userSocialLinkList',
                undefined,
                (currentList) => {
                  const index = currentList.findIndex((item) => item.id === id);

                  currentList.splice(index, 1);
                }
              )
            );

            dispatch(
              addNotification({
                title: 'Channel removed successfully'
              })
            );
          }
        } catch {
          dispatch(
            addNotification({
              title: 'Unknown error during channel removal'
            })
          );
        }
      }
    })
  })
});

export const {
  useUserSocialLinkListQuery,
  useUserSocialLinkAddMutation,
  useUserSocialLinkDeleteMutation,
  reducer,
  endpoints,
  middleware
} = socialLinksApi;
