import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'constants/api';

export const trackMusicTreeApi = createApi({
  reducerPath: 'trackMusicTree',
  tagTypes: ['TrackMusicTree'],
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getTrackMusicTree: builder.query<any, void>({
      query: () => ({
        url: 'TrackMusicTree'
      }),
      transformResponse: (result: any) => result?.data,
      providesTags: ['TrackMusicTree']
    })
  })
});


export const {
  useGetTrackMusicTreeQuery,
  endpoints,
  reducer,
  middleware
} = trackMusicTreeApi;
