export const SET_TURNSTILE_TOKEN = 'SET_TURNSTILE_TOKEN';
export const SET_TURNSTILE_LOADED = 'SET_TURNSTILE_LOADED';

export interface SetTurnstileTokenAction {
  type: typeof SET_TURNSTILE_TOKEN;
  payload: string | null;
}

export interface SetTurnStileLoadedAction {
  type: typeof SET_TURNSTILE_LOADED;
}

export type TurnstileTokeanActionTypes = SetTurnstileTokenAction | SetTurnStileLoadedAction;