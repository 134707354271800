import { IGenerator } from 'interfaces';
import {
  ADD_GENERATED_TRACK,
  GeneratorActionTypes,
  REMOVE_GENERATED_TRACK,
  RESET_IS_HIDE_BY_USER,
  TOGGLE_GENERATOR,
  TOGGLE_GENERATOR_BY_CLICK,
  HIDE_BY_USER
} from './types';

const initialState: IGenerator = {
  isOpened: true,
  isHideByUser: false,
  generatedTracks: []
};

export const generatorReducer = (
  state = initialState,
  action: GeneratorActionTypes
): IGenerator => {
  switch (action.type) {
    case TOGGLE_GENERATOR:
      return {
        ...state,
        isOpened: action.payload !== undefined ? action.payload : !state.isOpened
      };
    case TOGGLE_GENERATOR_BY_CLICK: {
      return {
        ...state,
        isOpened: !state.isOpened,
        isHideByUser: state.isOpened
      };
    }
    case RESET_IS_HIDE_BY_USER: {
      return {
        ...state,
        isHideByUser: false
      };
    }
    case HIDE_BY_USER: {
      return {
        ...state,
        isOpened: false,
        isHideByUser: true
      }
    }
    case ADD_GENERATED_TRACK: {
      return {
        ...state,
        generatedTracks: [...state.generatedTracks, action.payload]
      }
    }
    case REMOVE_GENERATED_TRACK: {
      return {
        ...state,
        generatedTracks: state.generatedTracks.filter(item => item !== action.payload)
      }
    }
    default:
      return state;
  }
};
