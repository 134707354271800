import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'constants/api';

export const similarTracksApi = createApi({
  reducerPath: 'similarTracks',
  tagTypes: ['TrackListSimilar'],
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getSimilarTracksById: builder.query<any, string>({
      query: (sessionId) => ({
        url: 'TrackListSimilar',
        params: {
          session_id: sessionId
        }
      }),
      transformResponse: (result: any) => result?.data,
      providesTags: ['TrackListSimilar']
    })
  })
});


export const {
  useGetSimilarTracksByIdQuery,
  endpoints,
  reducer,
  middleware
} = similarTracksApi;
