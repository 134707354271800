import { useMemo } from 'react';
import { useLocation as useReactRouterLocation } from 'react-router-dom';
import { usePathPrefix } from './useLanguage';

export function useLocation() {
  const prefix = usePathPrefix();
  const reactRouterLocation = useReactRouterLocation();

  return useMemo(() => {
    const { pathname, ...rest } = reactRouterLocation;

    return {
      ...rest,
      pathname: prefix && pathname.startsWith(prefix) ? pathname.slice(prefix.length) : pathname
    };
  }, [prefix, reactRouterLocation]);
}
