
export const SET_CURRENT_TRACK = 'SET_CURRENT_TRACK';
export const SET_TRACK_INFO = 'SET_TRACK_INFO';
export const SET_TRACK_PLAYING = 'SET_TRACK_PLAYING';
export const SET_TIME_PLAYING = 'SET_TIME_PLAYING';

export interface SetCurrentTrackAction {
  type: typeof SET_CURRENT_TRACK;
  payload: string;
}

export interface SetTrackInfoAction {
  type: typeof SET_TRACK_INFO;
  payload: any;
}

export interface SetTrackPlayingAction {
  type: typeof SET_TRACK_PLAYING;
  payload: boolean;
}

export interface SetTimePlayingAction {
  type: typeof SET_TIME_PLAYING;
  payload: number;
}


export type TrackActionTypes = SetCurrentTrackAction | SetTrackInfoAction;
