export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: {
    name: string;
    onComplete?: Function;
    onClose?: Function;
    props?: any;
    small?: boolean;
    extraSmall?:boolean;
    width?: number;
    large?:boolean;
    big?: boolean;
    fullHeight?: boolean;
    showMask?: boolean;
    closable?: boolean;
    maskClosable?: boolean;
  };
}

export interface HideModalAction {
  type: typeof HIDE_MODAL;
}

export type ModalActionTypes =
  | ShowModalAction
  | HideModalAction;
