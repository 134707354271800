import axios from 'axios';
import { PURCHASE_PARAM, PRICE_ID_PARAM } from 'constants/params-names';

type StripeSubscribeParams = {
  priceId: string;
  sessionId?: string;
  trial?: string;
  isBlackFriday?: boolean;
};

export const stripeSubscribe = ({ priceId, sessionId, trial, isBlackFriday }: StripeSubscribeParams) =>
  axios.post('/v1/StripeSubscribe', {
    method: 'StripeSubscribe',
    params: {
      stripe_price_id: priceId,
      trial_days: trial || undefined,
      use_coupon: isBlackFriday,
      redirect_url: sessionId
        ? `${window.location.origin}/render?${PURCHASE_PARAM}=true&${PRICE_ID_PARAM}=${priceId}&state=${JSON.stringify({ price_id: priceId, session_id: sessionId })}`
        : `${window.location.origin}/render?${PURCHASE_PARAM}=true&${PRICE_ID_PARAM}=${priceId}`,
      redirect_url_cancel: `${window.location.origin}/render`
    }
  });
