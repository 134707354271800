export const LANGUAGES = ['en', 'es', 'ko'] as const;

const LANGUAGES_NAMES: Record<(typeof LANGUAGES)[number], string> = {
  en: 'English',
  es: 'Spanish',
  ko: 'Korean'
};

export const LANGUAGES_OPTIONS = LANGUAGES.map((language) => ({
  title: LANGUAGES_NAMES[language],
  value: language
}));

export const LANGUAGE_PATH_PREFIXES = {
    'en': '',
    'es': '/es',
    'ko': '/ko'
  }