export const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'https://stage.mubert.com';
export const BACKEND_API_URL =
  process.env.API_URL_FOR_BACKEND || 'https://mubert-render-test-jhnefxpuia-ez.a.run.app';
const IS_BROWSER = typeof window !== 'undefined';
export const API_URL = IS_BROWSER ? `${REACT_APP_API_URL}/v1/` : `${BACKEND_API_URL}/v1/`;

export const STATIC_BACKEND_API_URL =
  process.env.STATIC_API_URL || 'https://static-eu.gcp.mubert.com/backend_content/render/test';

export const STATIC_API_URL = IS_BROWSER ? `${REACT_APP_API_URL}/static` : STATIC_BACKEND_API_URL;

export const CLOUDFLARE_ERROR_CODE = 'ERROR_CLOUDFLARE';