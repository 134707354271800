import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'constants/api';

const profilePageApi = createApi({
  reducerPath: 'profilePage',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    updateUserProfile: builder.mutation({
      query: (email_notification) => ({
        url: 'UserUpdateProfile',
        method: 'POST',
        body: {
          params: {
            email_notification
          }
        }
      })
    }),
    deleteUserProfile: builder.mutation<void, void>({
      query: () => ({
        url: 'UserDeleteProfile',
        method: 'POST',
        body: {
          params: {}
        }
      })
    })
  })
});

export const { useUpdateUserProfileMutation, useDeleteUserProfileMutation, reducer, middleware, endpoints } = profilePageApi;
