import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { settingsReducer } from './settings/reducer';
import { loginReducer } from './login/reducer';
import {
  playingReducer,
  timeReducer,
  trackReducer,
  trackInfoReducer
} from './current-track/reducer';
import { purchaseReducer } from './purchase/reducer';
import { playerMetaReducer } from './player-meta/reducer';
import { pricingInfoReducer } from './pricing-info/reducer';
import { notificationsReducer } from './notifications/reducer';
import { missingRoutesReducer } from './missing-routes/reducer';
import { modalsReducer } from './modals/reducer';
import { generatorReducer } from './generator/reducer';
import { reducer as trackListReducer } from './api/tracks';
import { reducer as trackMusicTreeReducer } from './api/track-music-tree';
import { reducer as sideBarStructureReducer } from './api/side-bar-structure';
import { reducer as playlistsPopularReducer } from './api/playlists-popular';
import { reducer as similarTracksReducer } from './api/similar-tracks';
import { reducer as tagsReducer } from './api/tags';
import { reducer as pageInfoReducer } from './api/page-info';
import { reducer as staffPicksTracksReducer } from './api/staff-picks-tracks';
import { reducer as downloadedTracksReducer } from './api/my-downloaded-tracks';
import { reducer as similarSearchReducer } from './api/similar-search';
import { reducer as faqReducer } from './api/faq';
import { reducer as whatsNewReducer } from './api/whatsnew';
import { turnstileTokenReducer } from './cloudfare/reducer';
import { reducer as profilePageReducer } from './api/profile-page';
import { reducer as socialLinksReducer } from './api/social-links';
import {reducer as paymentsReducer} from './api/payments';

export const history =
  typeof window !== 'undefined' ? createBrowserHistory({}) : createMemoryHistory({});

export const rootReducer = combineReducers({
  settings: settingsReducer,
  loggedIn: loginReducer,
  purchase: purchaseReducer,
  currentTrack: trackReducer,
  tracksInfo: trackInfoReducer,
  playing: playingReducer,
  timePlaying: timeReducer,
  playerMeta: playerMetaReducer,
  pricingInfo: pricingInfoReducer,
  notifications: notificationsReducer,
  router: connectRouter(history),
  modals: modalsReducer,
  generator: generatorReducer,
  generatedTracks: trackListReducer,
  trackMusicTree: trackMusicTreeReducer,
  sideBarStructure: sideBarStructureReducer,
  playlistsPopular: playlistsPopularReducer,
  similarTracks: similarTracksReducer,
  tags: tagsReducer,
  pageInfo: pageInfoReducer,
  staffPicksTracks: staffPicksTracksReducer,
  downloadedTracks: downloadedTracksReducer,
  similarSearch: similarSearchReducer,
  faq: faqReducer,
  whatsNew: whatsNewReducer,
  turnstileToken: turnstileTokenReducer,
  profilePage: profilePageReducer,
  socialLinks: socialLinksReducer,
  payments: paymentsReducer,
  missingRoutes: missingRoutesReducer
});
