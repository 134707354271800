import axios from 'axios';
import { PRICE_ID_PARAM, PURCHASE_PARAM, SINGLE_TRACK_PARAM } from 'constants/params-names';

type StripeOrderParams = {
  sessionId: string;
  priceId: string;
}

export const stripeOrder = ({sessionId, priceId}: StripeOrderParams) => axios.post('/v1/StripeOrder', {
  'method': 'StripeOrder',
  'params': {
    'session_id': sessionId,
    'stripe_price_id': priceId,
    'redirect_url': `${window.location.origin}/render/my-downloads?${PURCHASE_PARAM}=true&${PRICE_ID_PARAM}=${priceId}&${SINGLE_TRACK_PARAM}=true`,
    'redirect_url_cancel': window.location.origin + '/render/my-downloads'
  }
});
