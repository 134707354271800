import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces';
import { LANGUAGE_PATH_PREFIXES } from 'constants/languages'

export function useLanguage() {
  return useSelector((state: IAppState) => state.settings.language);
}


export function usePathPrefix() {
  const language = useLanguage();

  return LANGUAGE_PATH_PREFIXES[language] || '';
}