import { IPurchase } from 'interfaces';
import { PurchaseActionTypes, SET_IS_PURCHASING, SET_PURCHASE } from './types';


const initialState = {
  isPurchasing: false,
  data: null
};

export const purchaseReducer = (
  state: IPurchase = initialState,
  action: PurchaseActionTypes
): IPurchase => {
  switch (action.type) {
    case SET_PURCHASE:
      return {
        ...state,
        data: action.payload
      };
    case SET_IS_PURCHASING:
      return {
        ...state,
        isPurchasing: action.payload
      };
    default:
      return state;
  }
};
