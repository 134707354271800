import axios from 'axios';
import { getTurnstileToken } from 'utils/turnstile';

type AuthByEmailParams = {
  email: string;
  password: string;
  turnstileToken: string;
};

export const authByEmail = ({email, password, turnstileToken}: AuthByEmailParams) => axios.post('/v1/UserAuthEmail', {
  method: 'UserAuthEmail',
  token: getTurnstileToken(turnstileToken),
  params: {
    email,
    password
  }
});
