import React, { useEffect } from 'react';
import {storage} from 'utils/storage';
import './error-page.scss';

type ErrorPageProps = {
  code: string;
  error: Error;
};

export function setWithExpiry(key, value, ttl) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl
  };
  storage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemString = storage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    storage.removeItem(key);
    return null;
  }

  return item.value;
}


type ChunkErrorPageProps = {
  error: Error;
};


export function ChunkErrorPage({error}: ChunkErrorPageProps) {
  useEffect(() => {
    if (error?.message) {
      if (!getWithExpiry("chunk_failed")) {
        setWithExpiry("chunk_failed", "true", 10000);
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <div className='error-page'>
      <h1 className='error-page__header'>
        Application has been updated, page will be refreshed
      </h1>
    </div>
  );
}

export function ErrorPage({code, error}: ErrorPageProps) {

  return (
    <div className='error-page'>
      <h1 className='error-page__header'>
        Ooops something went wrong
      </h1>
      <p className='error-page__code'>
        Error code: <b>{code}</b>
      </p>
    </div>
  )
}
