import axios from 'axios';


type GenerateTrackParams = {
  menuChoice: string;
  playlist: string;
  duration: number;
  trackType?: 'track' | 'jingle' | 'loop' | 'mix'
};

export const generateTrack = ({menuChoice, playlist, duration, trackType = 'track'}: GenerateTrackParams) =>
  axios.post('/v1/TrackCreate', {
    method: 'TrackCreate',
    params: {
      'menu-choice': menuChoice,
      playlist,
      duration,
      track_type: trackType
    }
  });
